import React from "react"

import Layout from "../components/common/layout/layout"
import Navigation from "../components/common/navigation/navigation"
import Footer from "../components/sections/footer"

const Contact = () => (
  <Layout>
    <Navigation />
    <div style={{height: "60vh"}}>
        <div className="h-full flex-col p-8 md:p-16 flex justify-center container mx-auto">
            <h1 className="text-2xl md:text-3xl text-primary font-semibold">For any related concerns</h1>
            <h2 className="mt-1">You may email the celebration committee at <u>acmwap2021@national-u.edu.ph</u></h2>
        </div>
    </div>
    <Footer />
  </Layout>
)

export default Contact
